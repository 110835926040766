import React from 'react'
import "./successstory.css"
const SuccessStory = () => {
  return (
    <>
    <header class="Pageheader">
        <h2>Success Stories: Alumni of Bansal Classes Who Achieved Their Dreams</h2>
        <p>Every year, thousands of aspiring engineers embark on the rigorous journey of preparing for the IIT JEE, and many turn to Bansal Classes for guidance. Established in 1984 by V. K. Bansal, Bansal Classes has since nurtured countless students, helping them achieve their dreams of securing a place in the prestigious Indian Institutes of Technology (IITs). This article celebrates the success stories of a few distinguished alumni who have not only cracked the IIT JEE but have also excelled in their respective fields.</p>
    </header>

    <main class="content">
        <section class="story">
            <h2>Nitin Jain – AIR 1, IIT JEE 2009</h2>
            <p>Nitin Jain’s journey to the top of the IIT JEE merit list in 2009 is a testament to his hard work and the exceptional guidance provided by Bansal Classes. After securing All India Rank (AIR) 1, Nitin chose to pursue Computer Science and Engineering at IIT Delhi. His stellar performance continued in college, where he received multiple accolades for his academic excellence. Nitin is now a successful entrepreneur, having co-founded a tech startup that leverages AI to solve complex business problems. His dedication and innovative thinking continue to inspire countless students.</p>
        </section>

        <section class="story">
            <h2>Anjali Sharma – AIR 8, IIT JEE 2013</h2>
            <p>Anjali Sharma’s success story is one of perseverance and determination. Coming from a small town, Anjali faced numerous challenges in her preparation journey. However, with the comprehensive coaching and support from Bansal Classes, she secured AIR 8 in IIT JEE 2013. Anjali went on to study Electrical Engineering at IIT Bombay. She is now a leading researcher in renewable energy technologies and has published several papers in international journals. Her achievements in a traditionally male-dominated field have broken barriers and paved the way for more young women to pursue engineering.</p>
        </section>

        <section class="story">
            <h2>Rohit Gupta – AIR 15, IIT JEE 2015</h2>
            <p>Rohit Gupta’s story is a shining example of how dedication and the right mentorship can lead to outstanding success. After joining Bansal Classes, Rohit’s systematic preparation and the institute’s well-structured curriculum helped him achieve AIR 15 in IIT JEE 2015. He chose Mechanical Engineering at IIT Kanpur, where he continued to excel academically. Post-graduation, Rohit joined a leading automotive company and has been instrumental in developing cutting-edge technologies in electric vehicles. His contributions to the industry are a source of inspiration for many aspiring engineers.</p>
        </section>

        <section class="story">
            <h2>Priya Singh – AIR 22, IIT JEE 2017</h2>
            <p>Priya Singh’s journey is a remarkable tale of overcoming odds. Despite financial constraints, Priya’s unwavering determination and the scholarship support from Bansal Classes enabled her to secure AIR 22 in IIT JEE 2017. She pursued Chemical Engineering at IIT Madras and was actively involved in various research projects during her time there. Priya is now working with a leading multinational company, focusing on sustainable chemical processes. Her journey underscores the importance of resilience and the role of quality education in transforming lives.</p>
        </section>

        <section class="story">
            <h2>Rajesh Kumar – AIR 30, IIT JEE 2018</h2>
            <p>Rajesh Kumar’s story is one of passion and perseverance. Hailing from a rural background, Rajesh’s dream of studying at an IIT seemed distant until he joined Bansal Classes. With dedicated effort and exceptional guidance, he secured AIR 30 in IIT JEE 2018. Rajesh chose to study Civil Engineering at IIT Roorkee. His interest in infrastructure development led him to work on several notable projects even as a student. Today, Rajesh is a respected civil engineer, contributing to significant infrastructure projects across India. His journey from a village to the forefront of engineering is truly inspirational.</p>
        </section>

        <section class="conclusion">
            <h2>Conclusion</h2>
            <p>The success stories of these alumni highlight the transformative impact of Bansal Classes on students’ lives. The institute’s commitment to excellence, personalized attention, and robust curriculum have consistently produced top-ranking IITians who go on to excel in various fields. These stories not only inspire current students but also underscore the importance of quality education and mentorship in achieving one’s dreams. At Bansal Classes, every student’s success story begins with a dream, and through hard work, perseverance, and unparalleled support, those dreams turn into reality.</p>
        </section>
    </main>

    </>
  )
}

export default SuccessStory
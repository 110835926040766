import React, { useEffect } from 'react'
import HeadImage from '../../Header/HeadImage'
import NavBar from '../../Navigation bar/NavBar'
import Footer from '../../Footer/Footer'
import './desc.css'
import Img from '../../../assets/Nuclues.webp'
import { useNavigate } from 'react-router-dom'
const JeeNucleus = () => {
    useEffect(() => {
        document.title = 'Nucleus for JEE';
      }, []);
    const navigate = useNavigate()
    return (
        <>
            <NavBar />
            <HeadImage title="NUCLEUS FOR JEE" />
            <div className="course-desc">
                <div className="course-desc-box">
                    <div className="duration-eligble">
                        <div className="duration">
                            <h2>Duration</h2>
                            <p>1 Year During Class XII (Target JEE – 2025)</p>
                            
                        </div>
                        <div className="eliglible">
                            <h2>Eligiblity</h2>
                            <p>Class XI to XII Moving Students</p>
                        </div>
                    </div>


                    <div className="course-desc-text">
                        <h2>Course Description</h2>
                        <p>You have completed/are about to complete Class XI. If you are desirous of becoming an Engineering professional
                            through prestigious IIT’s & Engineering College, we take charge to guide you through it. Here Class XII PCM syllabus target IIT-JEE is to be covered. The portion of Class XI PCM will not be taught separately however it will be taken up only through DPPs. This course prepares you in the right
                            earnest to teach you the ways that are required to take on the IIT-JEE frontally at the end of class XII.</p>
                    </div>

                    <div className="apply-btn-box">
                        <button className='apply-btn' onClick={() => navigate('/student-registration')}>
                            Apply Now
                        </button>
                    </div>
                </div>


                <div className="div-img-box">
                    <img src={Img} alt="" />
                </div>
            </div>

            <Footer />

        </>
    )
}

export default JeeNucleus
import React from 'react'
import './mission.css'

const MisVis = () => {
    return (
        <>
            <div className="mis-vis mission">
                <div className="mis-heading">
                    <p className='mission-heading'> Mission </p>
                    <p className='mission-subheading'>
                        "Innovations Distuinguishes between a leader and a follower"
                    </p>


                </div>

                <p className='mission-text'>
                    Accepting change and making strategies accordingly is the quality of a leader.
                    This is what Bansal Classes have been doing – 'Adopting methods that best suits our
                    students to crack the JEE'. Mr.V.K Bansal, the best educator, in 1984, felt the need
                    of providing right direction to JEE aspirants for JEE preparations.
                    With his dedication and positive attitude, Bansal Classes produced
                    wonderful results each year which challenged themselves to produce even better results.
                </p>

            </div>
            <div className="mis-vis vission">
                <div className="mis-heading">
                    <p className='mission-heading'> Vision </p>
                    <p className='mission-subheading'>
                        "Leadership is the capacity to transalate vision into reality"
                    </p>
                </div>
                <p className='mission-text'>
                    As leaders in the field of informal education, we focus to enrich lives by challenging
                    all students to become successful,
                    lifelong learners who strengthen in a diverse and changing world.
                    We also focus to support schools in strengthening their examination pattern and
                    improving test taking skills of their students. We value learning as a lifelong process of change in the pursuit of knowledge and personal growth.
                </p>
            </div>
        </>
    )
}

export default MisVis
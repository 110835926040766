import React, { useEffect } from 'react'
import HeadImage from '../../Header/HeadImage'
import NavBar from '../../Navigation bar/NavBar'
import Footer from '../../Footer/Footer'
import './desc.css'
import Img from '../../../assets/Stirling.webp'
import { useNavigate } from 'react-router-dom'
const JeeSterling = () => {
    const navigate = useNavigate()
    useEffect(() => {
        document.title = 'Sterling for JEE';
      }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="STERLING FOR JEE" />
            <div className="course-desc">
                <div className="course-desc-box">
                    <div className="duration-eligble">
                        <div className="duration">
                            <h2>Duration</h2>
                            <p>1 Year after Class XII
                                (Target JEE – 2025)</p>
                        </div>

                        <div className="eliglible">
                            <h2>Eligiblity</h2>
                            <p>For 12th Passed Students</p>
                        </div>
                    </div>


                    <div className="course-desc-text">
                        <h2>Course Description</h2>
                        <p>If you have set your aims high if you want to touch the stars and reach the sky, and more importantly – if you have the courage to say that you shall settle for nothing less than the best, then you – the XII pass student – are perfectly suited to this course. You might have realized by now that you have everything – intelligence, capability, and willpower, but that you lack focused guidance – the do’s and don’ts of IIT-JEE & NEET preparation and a stimulating atmosphere. At this juncture, we offer you our helping hand. Come, resurrect your confidence, shape up your ideas, strengthen your foundation and get into the top gear of IIT-JEE & NEET preparation with a course that can easily claim to be at par with the best offered anywhere in India.</p>
                    </div>

                    <div className="apply-btn-box">
                        <button className='apply-btn' onClick={() => navigate('/student-registration')}>
                            Apply Now
                        </button>
                    </div>
                </div>


                <div className="div-img-box">
                    <img src={Img} alt="" />
                </div>
            </div>

            <Footer />

        </>
    )
}

export default JeeSterling
import React from 'react'
import './allcourses.css'
//import Img from '../../assets/courses image.jpeg'
import Nucleus from '../../assets/Nuclues.webp'
import prefond from '../../assets/PreFond.webp'
import Bulls from '../../assets/Bulls.webp'
import Stirling from '../../assets/Stirling.webp'
import CoursesTab from './CoursesTab'
import { Link, useNavigate } from 'react-router-dom'
const AllCoursesSection = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className="courses">

                <p className='course-section-heading'>Course Details</p>
                <div className="main-box">


                    <div className="all-courses-list">

                        <div className="bbox-1 bbox">
                            <div className="bbox-img">
                                <img src={Bulls} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>BULLS EYE FOR JEE</h2>
                                <p className='bbox-course-subheading'>CLASS X TO XI MOVING STUDENTS</p>
                                <p className='bbox-course-p'> The best brains compete to get an admission to this two-year
                                    legendary course offered by us. The course, unmatched in its rigor
                                    and precision, raises you to the very zenith of your preparation.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-bulls-eye')}>Read More</p>
                                </div>

                            </div>
                        </div>



                        <div className="bbox-2 bbox">
                            <div className="bbox-img">
                                <img src={Nucleus} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>NUCLEUS FOR JEE</h2>
                                <p className='bbox-course-subheading'>CLASS XI TO XII MOVING STUDENTS</p>
                                <p className='bbox-course-p'>You have completed / about to complete Class XI.
                                    If you are desirous of becoming an Engineering professional
                                    through prestigious IIT’s, we take charge to guide you through it.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-nucleus')}>Read More</p>
                                </div>
                            </div>
                        </div>

                        <div className="bbox-3 bbox">
                            <div className="bbox-img">
                                <img src={Stirling} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>STERLING FOR JEE</h2>
                                <p className='bbox-course-subheading'>FOR 12TH PASSED STUDENTS</p>
                                <p className='bbox-course-p'>If you have set your aims high if you want to touch the stars
                                    and reach the sky, and more importantly -
                                    if you have the courage to say that you shall settle.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-sterling')}>Read More</p>
                                </div>
                            </div>
                        </div>

                        <div className="bbox-4 bbox">
                            <div className="bbox-img">
                                <img src={Bulls} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>BULLS EYE FOR NEET</h2>
                                <p className='bbox-course-subheading'>CLASS X TO XI MOVING STUDENTS</p>
                                <p className='bbox-course-p'> The best brains compete to get an admission to this two-year
                                    legendary course offered by us. The course, unmatched in its rigor
                                    and precision, raises you to the very zenith of your preparation.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/neet-bulls-eye')}>Read More</p>
                                </div>

                            </div>
                        </div>



                        <div className="bbox-5 bbox">
                            <div className="bbox-img">
                                <img src={Nucleus} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>NUCLEUS FOR NEET</h2>
                                <p className='bbox-course-subheading'>CLASS XI TO XII MOVING STUDENTS</p>
                                <p className='bbox-course-p'>You have completed / about to complete Class XI.
                                    you are desirous of becoming an Doctor professional
                                    through prestigious AIIMS/Medical Colleges,, we take charge to guide you through it.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/neet-nucleus')}>Read More</p>
                                </div>
                            </div>
                        </div>

                        <div className="bbox-6 bbox">
                            <div className="bbox-img">
                                <img src={Stirling} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>STERLING FOR NEET</h2>
                                <p className='bbox-course-subheading'>FOR 12TH PASSED STUDENTS</p>
                                <p className='bbox-course-p'>You have completed/are about to complete Class XII.
                                    If you are desirous of becoming an Doctor professional through prestigious AIIMS/Medical Colleges,
                                    We take charge to guide you through it.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/neet-sterling')}>Read More</p>
                                </div>
                            </div>
                        </div>

                        <div className="bbox-1 bbox">
                            <div className="bbox-img">
                                <img src={prefond} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>GENIUS</h2>
                                <p className='bbox-course-subheading'>5TH TO 6TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>


                            </div>
                        </div>

                        <div className="bbox-7 bbox">
                            <div className="bbox-img">
                                <img src={prefond} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>BRILLIANT</h2>
                                <p className='bbox-course-subheading'>6TH TO 7TH MOVING</p>
                                <p className='bbox-course-p'> This Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                </p>
                                <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                {/* <div className="read">
                                    <p className='read-more'>Read More</p>
                                </div> */}

                            </div>
                        </div>



                        <div className="bbox-8 bbox">
                            <div className="bbox-img">
                                <img src={prefond} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>OCTAGON</h2>
                                <p className='bbox-course-subheading'>7TH TO 8TH MOVING</p>
                                <p className='bbox-course-p'>This Programme lay a strong foundation & prepare the students
                                    for Competitive Examinations organized at the state or National Level like NTSE, IJSO, NSTSE
                                    & Various Olympiads like NSO,
                                    IMO, IEO, UIEO, UCO, etc & also ensure excellence in school exams.
                                </p>
                                <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                {/* <div className="read">
                                    <p className='read-more'>Read More</p>
                                </div> */}
                            </div>
                        </div>

                        <div className="bbox-9 bbox">
                            <div className="bbox-img">
                                <img src={prefond} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>PEARL</h2>
                                <p className='bbox-course-subheading'>8TH TO 9TH MOVING</p>
                                <p className='bbox-course-p'>This Programme lay a strong foundation & prepare the students
                                    for Competitive Examinations organized at the state or National Level like NTSE, IJSO,
                                    NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO, etc &
                                    also ensure excellence in school exams.
                                </p>
                                <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                {/* <div className="read">
                                    <p className='read-more'>Read More</p>
                                </div> */}
                            </div>
                        </div>

                        <div className="bbox-10 bbox">
                            <div className="bbox-img">
                                <img src={prefond} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>ZENITH</h2>
                                <p className='bbox-course-subheading'>9TH TO 10TH MOVING</p>
                                <p className='bbox-course-p'>This Programme lay a strong foundation & prepare the students
                                    for Competitive Examinations organized at the state or National Level like NTSE, IJSO,
                                    NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO, etc &
                                    also ensure excellence in school exams.
                                </p>
                                <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                {/* <div className="read">
                                    <p className='read-more' onClick={() => navigate('/desc')}>Read More</p>
                                </div> */}
                            </div>
                        </div>


                    </div>

                    <CoursesTab />
                </div>
            </div>

        </>
    )
}

export default AllCoursesSection
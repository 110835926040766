import React, { useEffect } from 'react'
import HeadImage from '../components/Header/HeadImage'
import PrivacyPolicy from '../components/PrivacyAndRefund/PrivacyPolicy'
import Footer from '../components/Footer/Footer'
import NavBar from '../components/Navigation bar/NavBar'

const Privacy = () => {
  useEffect(() => {
    document.title = 'Bansal Classes Sagar - Privacy Policy';
  }, []);

  return (
    <>
      <NavBar />
      <HeadImage title="Privacy Policy" />
      <PrivacyPolicy />
      <Footer />
    </>
  )
}

export default Privacy
import React, { useEffect } from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import Footer from '../../components/Footer/Footer'
import HeadImage from '../../components/Header/HeadImage'

import AllCoursesSection from '../../components/Courses/AllCoursesSection'
const AllCourses = () => {

    useEffect(() => {
        document.title = 'Bansal Classes Sagar - All Courses';
      }, []);

    return (
        <>
            <NavBar />
            <HeadImage title="ALL Courses" />
            <AllCoursesSection/>

            <Footer />

        </>
    )
}

export default AllCourses
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/logo3.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import './nav.css';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import {
  useNavigate, Link

} from 'react-router-dom';

import boost from "../../assets/boost.webp"

function NavBar() {
  const navigate = useNavigate();
  

  return (
    <>
      
      
      <Navbar expand="xl" className="bg-body-tertiary nav">
        <Container className='container'>
          <Nav className="logo-box">
            <Navbar.Brand onClick={() => navigate('/')} className='brand-img'>
              <img
                src={Logo}
                width="250"
                height="100"
                className="d-inline-block align-top logo"
                alt="bansal logo"
              />
            </Navbar.Brand>
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navv">

              {/* Home */}
              <Nav.Link onClick={() => navigate('/')} className='myFont' id="myFont">Home</Nav.Link>

              {/* about  */}
              <NavDropdown title="About" id="basic-nav-dropdown" className='myFont'>
                <NavDropdown.Item onClick={() => navigate('/aboutus')}> About Us</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/directors-msg')}>
                  Director's Message
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/mision&vision')}>Mission and Vision</NavDropdown.Item>
              </NavDropdown>

              {/* Courses  */}
              <NavDropdown title="Courses" id="basic-nav-dropdown" className='myFont'>
                <NavDropdown.Item onClick={() => navigate('/jeeadvanced')}>JEE Advanced</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/neet-course')}>
                  NEET UG
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/pre-foundation')}>Pre Foundation</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/all-course')}>All Courses</NavDropdown.Item>
              </NavDropdown>

              {/* Registration  */}
              <NavDropdown title="Registration" id="basic-nav-dropdown" className='myFont'>
                <NavDropdown.Item onClick={() => navigate('/student-registration')}>Student Registration</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/boost-registration')}>
                  Boosts Registration
                </NavDropdown.Item>
              </NavDropdown>


                {/* contact */}      
              <Nav.Link onClick={() => navigate('/contact')} className='myFont' id="myFont">Contact</Nav.Link>̥
              {/* Boosts  */}

             

     
            </Nav>

<Nav className="boost-logo">
            <Navbar.Brand onClick={() => navigate('/boost')} className='brand-img'>
              <img
                src={boost}
                width="150"
                height="80"
                className="d-inline-block align-top"
                alt="bansal logo"
              />
            </Navbar.Brand>
          </Nav>
            
          </Navbar.Collapse>
          
          
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
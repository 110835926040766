import React from 'react'
import './terms.css'
const TermsSection = () => {
    return (
        <>
            <div className="terms-box">
                <ol type='1'>
                    <li>Types of Debit/Credit Cards currently accepted for online payment are Visa Card and Master Card .
                        You will not be able to use other credit/debit cards to make online payments.
                        We may choose to add to these methods or remove any of them as deemed appropriate.
                        The system will issue a notification whenever such changes are made.</li>
                    <li>
                    Each time you submit a transaction request, 
                    you authorize us to initiate a payment request in your name on your account, payable to us.
                    </li>
                    <li>
                    You may not stop payment of electronic fund transfers once you have confirmed a transaction; therefore, you should not employ electronic payment for services unless 
                    you are satisfied that you will not need to stop payment.
                    </li>
                    <li>We will not be in a position to update or change personal information, such as, but not limited to, name, address, phone numbers and e-mail addresses, with the financial institution issuing the credit card/smart card. Your payment request will result in a non-authorization if you enter personal information different from that on the credit card/smart card.</li>
                    <li>
                    You agree not to give or make available your card information to unauthorized individuals. We will not be responsible for unauthorized persons making transactions on your card. If you permit other persons to use your credit/debit card, you are responsible for any transactions they authorize.
                    </li>
                    <li>
                    In no event shall we be liable for special, punitive, consequential or incidental damages, including without limitation, lost revenue, profits or other benefit.
                    </li>
                    <li>
                    We will not be liable to you in the following instances:
                        <ol type='a'>
                            <li>If the card account is not authorized to you or has expired due to insufficient funds on your card account</li>
                            <li>If your funds are subject to legal process or other encumbrance restricting the transaction.</li>
                            <li>If circumstances beyond our control (such as fire or flood) prevent the transaction, despite reasonable precautions that we have taken.</li>
                        </ol>
                    </li>
                    <li>
                    To protect your privacy and security, we will not capture your card details on our system.
                    </li>
                    <li>You authorize us to keep a record of the electronic transactions that you, or any person acting on your behalf, conduct on the system, and to keep those records as long as we consider it necessary. If our records about a payment are different than your records, our records will govern.</li>
                    <li>Your ability to make payments online may not be fulfilled if the system cannot verify your identity or other necessary information. You must have a valid user id and password to be able to access the system and make online payments.</li>
                    <li>Any documentation provided to you, which indicates that an electronic payment was authorized and completed, shall be admissible as evidence of such transfer and shall constitute prima facie proof that such transfer was made.</li>
                    <li>We will not be responsible for performance failure as a result of power failures, equipment malfunctions, and suspension of payment by a bank, refusal or delay by a bank to accept the funds transfer, war, emergency conditions, fire, earthquake or other circumstances not within our control. These difficulties may extend the time when payments are credited.</li>
                    <li>We shall exercise good faith and reasonable care in processing your payments. You shall similarly exercise good faith and reasonable care in observing and maintaining security procedures on your card.</li>
                    <li>Any fee refund will be made as per the rules of the institute mentioned under Refund Rules. In case of any disputes regarding fee payments, the institute reserves the right to take appropriate action on merits of the case.</li>
                
                </ol>
            </div>
        </>
    )
}

export default TermsSection
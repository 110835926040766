import React from 'react'
import './courses.css'
import Img1 from '../../assets/11.webp'
import Img2 from '../../assets/12.webp'
import Img3 from '../../assets/13.webp'
import Img4 from '../../assets/14.webp'
import { useNavigate } from 'react-router-dom'

const Courses = () => {
    const navigate = useNavigate()
    return (
        <>
            <section className='courses'>
                <div className="heading">
                    <p className='course-heading'>Our Courses</p>
                    <p className='course-subheading'>The Classroom Learning Program is a professional development
                        initiative focusing on differentiated instruction.</p>
                </div>

                <div className="course-list">
                    <div className="course-box" onClick={()=>navigate('jeeadvanced')}>
                        <img src={Img4} alt="" className='courseImg' />
                        <p className='courseName'>JEE Advanced</p>
                    </div>
                    <div className="course-box" onClick={()=>navigate('neet-course')}>
                        <img src={Img1} alt="" className='courseImg' />
                        <p className='courseName'>NEET UG</p>
                    </div>

                    <div className="course-box" onClick={()=>navigate('pre-foundation')}>
                        <img src={Img3} alt="" className='courseImg' />
                        <p className='courseName'>PRE FOUNDATION</p>
                    </div>
                    <div className="course-box" onClick={()=>navigate('all-course')}>
                        <img src={Img2} alt="" className='courseImg' />
                        <p className='courseName'>POPULAR COURSE</p>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Courses
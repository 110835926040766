import React from 'react'
import { useNavigate } from 'react-router-dom'


const CoursesTab = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className="links-box">
                <h1>Courses</h1>
                <div className="links-list">
                    <p className='link' onClick={() => navigate('/jeeadvanced')}>JEE ADVANCED</p>
                    <p className='link' onClick={() => navigate('/neet-course')}>NEET UG</p>
                    <p className='link' onClick={() => navigate('/pre-foundation')}>PRE FOUNDATION</p>
                    <p className='link' onClick={() => navigate('/all-course')}>ALL COURSES</p>
                </div>
            </div>
        </>
    )
}

export default CoursesTab
import React, { useEffect } from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import HeadImage from '../../components/Header/HeadImage'
import DirectorsMessage from '../../components/About/DirectorsMessage'
import Footer from '../../components/Footer/Footer'

const DiresctorsMsg = () => {
  useEffect(() => {
    document.title = 'Bansal Classes Sagar - Directors Message';
  }, []);
  return (
    <>
        <NavBar/>
        <HeadImage title="Directors Messages"/>
        <DirectorsMessage/>
        <Footer/>
    
    </>
  )
}

export default DiresctorsMsg
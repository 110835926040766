import React from 'react'
// import HeadImage from '../Header/HeadImage'
import './privacy.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const PrivacyPolicy = () => {
    return (
        <>
            {/* <HeadImage title="Privacy Policy" /> */}
            <div className="privacy-box">
                <p className='privacy-text'>
                    <ChevronRightIcon className='privacy-arrow-icon' />
                    You may visit our website without revealing any personal information wherever permissible.
                    Certain transactions may require submission of personal information like profile updates and
                    certain databases. We will not sell, swap or rent, or otherwise disclose to any third party any
                    personal information for commercial purpose and such information will be utilized only for the purpose
                    stated. To accomplish such purpose we may disclose the information to our employees,
                    consultants and other concerned having a genuine need to know the information.</p>
                <p className='privacy-text'>
                <ChevronRightIcon className='privacy-arrow-icon' />
                    Our web server may record the numerical Internet Protocol (IP) address of the
                    computer you are using, Information about your browser and operating system, date and
                    time of access and page which linked you to our website. This information may be used to
                    administer and improve our
                    website and to generate aggregate statistical reports and such like purposes.
                </p>
                <p className='privacy-text'>
                <ChevronRightIcon className='privacy-arrow-icon' />
                    We may use cookies and pixels or transparent GIF files to track session information
                    and/or to deliver customizable and personalized services and information and/or for other
                    purposes of website such information is anonymous and not personally identifiable.
                    Any information collected ordinarily is not disclosed to third parties but may be disclosed in limited circumstances as and if required by law, court order, statutory bodies, rules & regulations or for improving our
                    website or for the security of our network or for any purpose as deemed necessary.
                </p>
                <p className='privacy-text'>
                <ChevronRightIcon className='privacy-arrow-icon' />
                    Our website contains links to other independently run websites within our
                    network and to some websites outside our domain that are not controlled by us and are
                    not covered by this privacy policy. We are not responsible for the privacy practices,
                    security or content of such websites. While we make our best efforts to protect the privacy
                    of users however we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk. This privacy policy may be
                    revised/modified/amended at any point of time at the sole discretion of the Institute.
                </p>
            </div>
        </>
    )
}

export default PrivacyPolicy
import React from 'react'
import { Link } from 'react-router-dom'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './whatsapp.css'
const Whatsapp = () => {
    return (
        <>
            <Link to="https://api.whatsapp.com/send?phone=916232102603 " className="float" target="_blank">
                <WhatsAppIcon class="my-float" style={{ fontSize: '1rem', color:"#fff" }}/>
            </Link>
        </>
    )
}

export default Whatsapp
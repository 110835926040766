import React from 'react'
import Logo from '../../assets/Photo 01.webp'
import './about.css'
const AboutDirector = () => {
    return (
        <>
            <div className='about-dir'>
                <div className='dir-msg'>
                    <p className='about-section-heading'>
                        Study center of <span className='text-purple'>bansal classes</span>
                    </p>

                    <p className='msg'>
                        “Take the advantage of what we offer, you will build a strong beam
                        upon which you can lift your life. That lever will be strengthened through
                        the addition of our experience, mentors, culture and knowledge; components that you will find at Bansal Classes. When that strong beam is rested open the rock of your faith, character, and talent,
                        then like Archimedes, you will envision ways to lift the world”.
                    </p>

                    {/* <p className='dir-name'>Mr. V.K Bansal</p>
                    <p className='msg'>Founder Bansal classes</p> */}
                </div>

                <div className="logo1">
                    <figure>
                        <img src={Logo} alt="logo" />
                            <figcaption>Bansal Classes Sagar</figcaption>
                    </figure>
                </div>
            </div>
        </>
    )
}

export default AboutDirector
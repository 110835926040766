import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './newsslider.css'; // Optional: You can use this CSS file for styling

const NewsSlider = () => {
  // Sample news data with links to the actual news pages
  const newsData = [
    {
      title: "Top Strategies for Cracking IIT JEE: Insights from Bansal Classes Experts",
      link: "/tips"
    },
    {
      title: "Success Stories: Alumni of Bansal Classes Who Achieved Their Dreams",
      link: "/success-story"
    },
    
    {
      title: "The Test for JEE & NEET Division is To be Conducted on 17.11.24",
      link: "/test-results" // Route path for navigation
    },
    {
      title: "The Test for JEE & NEET Division is To be Conducted on 10.11.24 ",
      link: "/test-results" 
    },
    {
      title: "The Results for Test Conducted on 10.11.24 for Junior Division are out",
      link: "/test-results"
    },    
    {
      title: "Register for India’s Biggest Scholarship Exam with Scholarship worth 3cr",
      link: ""
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to go to the next news item
  const goToNextNews = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
  };

  // Automatically change the news every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(goToNextNews, 3000); // 3000ms = 3 seconds
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  // Function to handle news item click
  const handleNewsClick = () => {
    navigate(newsData[currentIndex].link); // Use navigate to go to the desired route
  };

  return (
    <div className="news-slider-container">
      <div className="news-slider" onClick={handleNewsClick}> {/* Add onClick to the container */}
        <p className="news-item">
          <span className="latest-update">Latest Updates!</span>
          <span className='news-link'>{newsData[currentIndex].title}</span> {/* Display the title directly */}
        </p>
      </div>
    </div>
  );
};

export default NewsSlider;

import React, { useEffect } from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import Footer from '../../components/Footer/Footer'
import HeadImage from '../../components/Header/HeadImage'
import JeeAdvancedSection from '../../components/Courses/JeeAdvancedSection'


const JeeAdvanced = () => {

    useEffect(() => {
        document.title = 'Bansal Classes Sagar - JEE Courses';
      }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="JEE ADVANCED"/>
            <JeeAdvancedSection/>

            <Footer />



        </>
    )
}

export default JeeAdvanced
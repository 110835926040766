// import React, { useEffect, useState } from 'react'
import './about.css'
// import Img from '../../assets/teacher.svg'

const About = () => {
    let course_count = 11;
    let staff_count = 15;
    let academic_count = 17;
    let students_count = "100+";

    return (
        <>
            <section className='section about'>
                <div className='about-image'>
                    <iframe src="https://www.youtube.com/embed/I9H0PlbM7NU?si=FtmI8gQEdtWjIslc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div className='about-content'>
                    <div className='section-heading'>
                        <p>
                            About Us
                        </p>
                    </div>


                    <p className='display-4'>
                        A New Era of education
                    </p>

                    <p className='display-3'>
                        Bansal classes [kota] now in Sagar
                    </p>

                    <p className='about-msg'>
                        Established in 1984, Bansal Classes has evolved into a leading institution for IIT and NEET preparation. With a trusted brand in education,
                        we have helped numerous students achieve their dream careers through meticulous execution, hard work, and dedication. Our objective is to provide equal
                        opportunities and enhance student development through quality teaching. Led by Mr. V.K. Bansal, a renowned educator, we consistently adapt and adopt effective
                        methods to ensure success in cracking the JEE exams.
                    </p>

                    {/* colorful boxes  */}
                    <div className="boxes">
                        <div className='box first-box'>
                            <p className='counter'>
                                {course_count}+
                            </p>
                            <p className='box-msg'>
                                popular
                                <br />
                                courses
                            </p>
                        </div>
                        <div className='box second-box'>
                            <p className='counter'>
                                {staff_count}+
                            </p>
                            <p className='box-msg'>
                                good
                                <br />
                                staff
                            </p>
                        </div>
                        <div className='box third-box'>
                            <p className='counter'>
                                {academic_count}+
                            </p>
                            <p className='box-msg'>
                                skilled
                                <br />
                                insturctor
                            </p>
                        </div>
                        <div className='box fourth-box'>
                            <p className='counter'>
                                {students_count}
                            </p>
                            <p className='box-msg'>
                                happy
                                <br />
                                students
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default About
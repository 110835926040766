import React, { useEffect } from 'react'
import HeadImage from '../../Header/HeadImage'
import NavBar from '../../Navigation bar/NavBar'
import Footer from '../../Footer/Footer'
import '../JEECourses/desc.css'
import Img from '../../../assets/Bulls.webp'
import { useNavigate } from 'react-router-dom'
const NeetBulls = () => {
    useEffect
    (() => {
        document.title = 'Bulls Eye for NEET';
      }, []);
    const navigate = useNavigate()
    return (
        <>
            <NavBar />
            <HeadImage title="BULLS EYE FOR NEET" />
            <div className="course-desc">
                <div className="course-desc-box">
                    <div className="duration-eligble">
                        <div className="duration">
                            <h2>Duration</h2>
                            <p>2 Years during class XI and XII</p>
                        </div>

                        <div className="eliglible">
                            <h2>Eligiblity</h2>
                            <p>Class X to XI Moving Students</p>
                        </div>
                    </div>


                    <div className="course-desc-text">
                        <h2>Course Description</h2>
                        <p>The best brains compete to get admission to this two-year legendary course offered by us.
                            The course, unmatched in its rigor and precision, raises you to the very zenith of your preparation.
                            The stimulating atmosphere of the institute, small batches (yes-we belief in quality), and one-to-one interaction with the instructor ensure that every rough edge is smoothened, every small doubt is cleared, that’s why most of our toppers are from this course. More importantly, we train you to think analytically, we give you a systematic approach to problem-solving – a must for NEET-UG. If you think you are bright, if you think you are capable,
                            if you think that you can make it to the top, this course is precisely for you.</p>
                    </div>

                    <div className="apply-btn-box">
                        <button className='apply-btn' onClick={() => navigate('/student-registration')}>
                            Apply Now
                        </button>
                    </div>
                </div>


                <div className="div-img-box">
                    <img src={Img} alt="" />
                </div>
            </div>

            <Footer />

        </>
    )
}

export default NeetBulls
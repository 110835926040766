import React, { useEffect } from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import Footer from '../../components/Footer/Footer'
import HeadImage from '../../components/Header/HeadImage'
// import JeeAdvancedSection from '../../components/Courses/JeeAdvancedSection'
import NeetSection from '../../components/Courses/NeetSection'


const Neet = () => {
    useEffect(() => {
        document.title = 'Bansal Classes Sagar - NEET Courses';
    }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="NEET UG" />
            <NeetSection />

            <Footer />



        </>
    )
}

export default Neet
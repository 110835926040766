import React, { useEffect } from 'react'
import HeadImage from '../../components/Header/HeadImage'
import MisVis from '../../components/About/MisVis'
import Footer from '../../components/Footer/Footer'
import NavBar from '../../components/Navigation bar/NavBar'

const MissionVision = () => {
    useEffect(() => {
        document.title = 'Bansal Classes Sagar - Mission and Vision';
      }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="Mission and Vision" />
            <MisVis />
            <Footer />

        </>
    )
}

export default MissionVision

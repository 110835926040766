import React, { useEffect } from 'react'
import NavBar from '../components/Navigation bar/NavBar'
import Footer from '../components/Footer/Footer'
import ContactSection from '../components/ContactSection/ContactSection'
import HeadImage from '../components/Header/HeadImage'

const ContactPage = () => {

  useEffect(() => {
    document.title = 'Bansal Classes Sagar - Contact';
  }, []);

  return (
    <>
      <NavBar />
      <HeadImage title="Contact Us" />
      <ContactSection />

      <Footer />

    </>
  )
}

export default ContactPage
import React, { useEffect } from 'react'
import HeadImage from '../components/Header/HeadImage'
import TermsSection from '../components/PrivacyAndRefund/TermsSection'
import Footer from '../components/Footer/Footer'
import NavBar from '../components/Navigation bar/NavBar'

const Terms = () => {
    useEffect(() => {
        document.title = 'Bansal Classes Sagar - Terms and Conditions';
      }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="Terms and Condition" />
            <TermsSection />
            <Footer />
        </>
    )
}

export default Terms
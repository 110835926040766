import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import './boost.css'
import { Link, useNavigate } from 'react-router-dom';
const DetailsTab = () => {
    const navigate = useNavigate()
    return (


        <>
            <div className="tab-know-boost">
                <div className='tab-compo'>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-4 tab-bg"
                    >
                        <Tab eventKey="home" title="Eligiblity">
                            <div className="elgiblity tab">
                                <h2>Eligiblity</h2>
                                <h3>Class V, VI, VII, VIII, IX, X, XI & XII.</h3>
                                <p>Class V, VI, VII, VIII, IX, X, XI (Engineering and Medical)
                                    & XII (Engineering and Medical).</p>
                            </div>


                        </Tab>
                        <Tab eventKey="profile" title="Test Date">
                            <div className="test-date tab">
                                <h2>Test Date</h2>
                                <p>
                                    Online: 2nd, 3rd, 4th, 5th, 6th & 26th October
                                </p>
                                <p>
                                    Offline: 6th & 26th October
                                </p>
                            </div>
                        </Tab>
                        <Tab eventKey="Sample Papers" title="Sample Papers">
                            <div className="sample-papers tab">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>

                                            <th>Class</th>
                                            <th>Sample Papers</th>
                                            <th>Syllabus</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        <tr>
                                            <td>Class VI</td>
                                            <td><Link to="https://drive.google.com/file/d/1c0vEjPlGFTLWHWarCKlTg4EdHVPPi9zN/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/16XuNvxPDytI8arqA8qz4sJp_WM8NmsAE/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>
                                        <tr>
                                            <td>Class VII</td>
                                            <td><Link to="https://drive.google.com/file/d/1ncmw8uGS9dIRCsE7HidImhr3pAKa6Spt/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1RGeO0HD89ikKQ8r-8ZIeDpX472sJb7Gi/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>
                                        <tr>
                                            <td>Class VIII</td>
                                            <td><Link to="https://drive.google.com/file/d/1jxQYf3lKvLAS96Q90k_x3dYQuqyCUVmx/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1KleN6WbLwvy-PC1HsiXoMY7Dj0DOA3nU/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>
                                        <tr>
                                            <td>Class IX</td>
                                            <td><Link to="https://drive.google.com/file/d/1YN7IHCTXL54ZKC7_vFZKub8Etr_XX9_Z/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1ceKDSVcbNrGqVj6F5g7k276cB4G9IXeg/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>

                                        <tr>
                                            <td>Class X</td>
                                            <td><p>Get</p></td>
                                            <td><Link to="https://drive.google.com/file/d/18I_lTYv_Hg5LfemQuRv8g6AoTGXrZblw/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>


                                        <tr>
                                            <td>Class XI MED</td>
                                            <td><Link to="https://drive.google.com/file/d/1k2TFUlvo9dFkBE1FojSbmGb9mB1d20c4/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1NE_DF6NNKcjoaDoALMlIqPPEaKZJ37YG/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>

                                        <tr>
                                            <td>Class XI ENG </td>
                                            <td><Link to="https://drive.google.com/file/d/18I_lTYv_Hg5LfemQuRv8g6AoTGXrZblw/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1nm_gIRIdviEAZ9l-a7yNeUQ95Xn2n2WT/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>

                                        <tr>
                                            <td>Class XII MED  </td>
                                            <td><Link to="https://drive.google.com/file/d/19RPeyw91EOqB5pPc2VeuqHPTmz2i3CFx/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1Q3m-agb38_QrGCTrmwXk9LA40B2MtyZH/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>

                                        <tr>
                                            <td>Class XII ENG </td>
                                            <td><Link to="https://drive.google.com/file/d/1oEmmxtEEoTbIkLob98SNBPYSghVzG7za/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1aSuPosNnzWgLUjmM2AFATPSOqBURDoIA/view?usp=sharing">Get</Link></td>
                                        </tr>

                                        <tr>
                                            <td>Class XII+ MED  </td>
                                            <td><Link to="https://drive.google.com/file/d/1Izet_L3tiVZAVn-2yVdH-bMddqTYVN2J/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1q_iHw6fCG53qXHexe6CT8Z6xr-dRptOK/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>


                                        <tr>
                                            <td>Class XII+ ENG </td>
                                            <td><Link to="https://drive.google.com/file/d/1UQjDvI-kaYdoDeqhiyW0_m_7ryScmlQQ/view?usp=sharing" target="_blank">Get</Link></td>
                                            <td><Link to="https://drive.google.com/file/d/1_4Z31EoLna2MlvegwDFNLfv8W-sBvzbH/view?usp=sharing" target="_blank">Get</Link></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>


                        </Tab>

                        <Tab eventKey="How to Apply" title="How to Apply">
                            <div className="apply tab">
                                <h2>How to Apply</h2>
                                <p><Link to='/boost-registration'>Online</Link> or Offline visit to Bansal Study Center, Sagar</p>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                <div className="know-boost">
                    <h2>Know about Boost</h2>
                    <p>Enroll yourself for the most prestigious scholarship examination of India for getting
                        admission in Bansal Classes, Guwahati and study centres across India.</p>
                    <button onClick={() => navigate('/boost-registration')} className='know-btn'>Enroll Now</button>
                </div>
            </div>

        </>
    )
}

export default DetailsTab
import React from 'react'
import './jee.css'
import Nucleus from '../../assets/Nuclues.jpeg'
import Bulls from '../../assets/Bulls.jpeg'
import Stirling from '../../assets/Stirling.jpeg'
import CoursesTab from './CoursesTab'
import { useNavigate } from 'react-router-dom'
const JeeAdvancedSection = () => {

    const navigate = useNavigate()
    return (
        <>
            <div className="courses">

                <p className='course-section-heading'>Course Details</p>
                <div className="main-box">


                    <div className="courses-list">

                        <div className="bbox-1 bbox">
                            <div className="bbox-img">
                                <img src={Bulls} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>BULLS EYE FOR JEE</h2>
                                <p className='bbox-course-subheading'>CLASS X TO XI MOVING STUDENTS</p>
                                <p className='bbox-course-p'> The best brains compete to get an admission to this two-year
                                    legendary course offered by us. The course, unmatched in its rigor
                                    and precision, raises you to the very zenith of your preparation.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-bulls-eye')}>Read More</p>
                                </div>

                            </div>
                        </div>



                        <div className="bbox-2 bbox">
                            <div className="bbox-img">
                                <img src={Nucleus} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>NUCLEUS FOR JEE</h2>
                                <p className='bbox-course-subheading'>CLASS XI TO XII MOVING STUDENTS</p>
                                <p className='bbox-course-p'>You have completed / about to complete Class XI.
                                    If you are desirous of becoming an Engineering professional
                                    through prestigious IIT’s, we take charge to guide you through it.
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-nucleus')}>Read More</p>
                                </div>
                            </div>
                        </div>

                        <div className="bbox-3 bbox">
                            <div className="bbox-img">
                                <img src={Stirling} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>STERLING FOR JEE</h2>
                                <p className='bbox-course-subheading'>FOR 12TH PASSED STUDENTS</p>
                                <p className='bbox-course-p'>If you have set your aims high if you want to touch the stars
                                    and reach the sky, and more importantly -
                                    if you have the courage to say that you shall .
                                </p>
                                <div className="read">
                                    <p className='read-more' onClick={()=> navigate('/jee-sterling')}>Read More</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <CoursesTab/>
                </div>
            </div>

        </>
    )
}

export default JeeAdvancedSection
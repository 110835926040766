import React, { useRef, useState } from 'react'
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import './contactsection.css'
// import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
// import Button from '@mui/material/Button';

const 
ContactSection = () => {



  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbyKTaZAnZCQMZazu9trMmIsF0jmxTXpin0u35w2e9FN43v-QzQSi7p3zqwSX8G1gG_b/exec";
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch(scriptUrl, {
      method: 'POST',
      body: new FormData(formRef.current),

    }).then(res => {
      console.log("SUCCESSFULLY SUBMITTED")
      setLoading(false)
      setName('');
      setPhone('');
      setEmail('');
      setMsg('');
      setSubject('');
      setType('');

    })
      .catch(err => console.log(err))
  }





  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [msg, setMsg] = useState('');
  const [type, setType] = React.useState('');
  const [subject, setSubject] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };
  
  

  return (
    <>

      <section className='section contact-section'>
        <div className="contact-info-box">

          <div className="location info-box">
            <div className="location-icon icon">
              <PlaceIcon></PlaceIcon>
            </div>

            <div className="location-info info">
              <p className='info-heading'>Our Location</p>
              <p className='info-p'>Opposite UTD ground, <br />Near Civil Lines,<br /> Manorama Colony,
                Sagar(M.P.) <br /> 470001</p>
            </div>
          </div>

          <div className="call-us info-box">
            <div className="call-us-icon icon">
              <CallIcon></CallIcon>
            </div>

            <div className="call-us-info info">
              <p className='info-heading'>Call Us</p>
              {/* <p className='info-p' onClick={handlewsp1}>6232102601
                <WhatsAppIcon className='wsp-icon'/></p>
              <p className='info-p' onClick={handlewsp2}>6232102602</p>
              <p className='info-p'onClick={handlewsp3}>6232102603</p> */}
              <Link to='https://api.whatsapp.com/send?phone=916232102601' className='link-p'>6232102601<WhatsAppIcon className='wsp-icon'/></Link>
              <Link to='https://api.whatsapp.com/send?phone=916232102602' className='link-p'>6232102602<WhatsAppIcon className='wsp-icon'/></Link>
              <Link to='https://api.whatsapp.com/send?phone=916232102603' className='link-p'>6232102603<WhatsAppIcon className='wsp-icon'/></Link>

            </div>
          </div>

          <div className="email-us info-box">
            <div className="email-icon icon">
              <EmailIcon />
            </div>

            <div className="email-info info">
              <p className='info-heading'>Email Us</p>
              <Link to="mailto:admin.sagar@bansal.ac.in" className='link-p'>admin.sagar@bansal.ac.in</Link>
              <Link to="mailto:sagarbansalclasses@gmail.com" className='link-p'>sagarbansalclasses@gmail.com</Link>
              {/* <p className='info-p'>
              admin.sagar@bansal.ac.in
              </p>
              <p className='info-p'>
              sagarbansalclasses@gmail.com
              </p> */}
            </div>
          </div>

        </div>


        <div className="contact-form">
          <div className="heading">
            <p className='form-heading'>Need help?</p>
            <p className='form-subheading'>Send us a message</p>
          </div>
          <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
            <div className="form-box">
              <div className="row-1 row-form">
                <TextField type="text" value={name} id="name" name="name" required label="Your Name" variant="standard" onChange={(e) => setName(e.target.value)}/>
                <TextField id="email" value={email} type="email" name="email" required label="Your Email" variant="standard" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="row-2 row-form">
                <TextField id="phone" value={phone} type="number" name="phone" required label="Your Phone Number" variant="standard" onChange={(e) => setPhone(e.target.value)}/>
                <FormControl required variant="standard">
                  <InputLabel id="type-label">You Are?</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    name="type"
                    value={type}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value={"student"}>Student</MenuItem>
                    <MenuItem value={"Parent"}>Parent</MenuItem>
                    <MenuItem value={"Teacher"}>Teacher</MenuItem>
                    <MenuItem value={"Career"}>Career</MenuItem>
                  </Select>
                </FormControl>


              </div>

              <div className="row-form row-3">

                <TextField id="subject" value={subject} name='subject' type='text' required label="Subject" variant="standard" onChange={(e) => setSubject(e.target.value)}/>
                <TextField
                  id="message"
                  required
                  name='message'
                  type='text'
                  label="Message"
                  multiline
                  rows={4}
                  onChange={(e) => setMsg(e.target.value)}
                  // defaultValue="Default Value"
                  variant="standard"
                  value={msg}
                />
              </div>

              <div className="btn-box row-4 row-form">
                {/* <Button type="submit" value={loading ? "Loading..." : "SEND MESSAGE"} ></Button> */}
                <input type="submit" className="btn-form myBtn" value={loading ? "Loading..." : "SEND MESSAGE"} />
              </div>
            </div>
          </form>

        </div>


      </section>


    </>
  )
}

export default ContactSection

//https://script.google.com/macros/s/AKfycbzlr7t7EDfz3FXzDxQ5n2zHR1nv5ZWBCboedUB2SV-St_3g5Q3jFkEYRD45trv726pq/exec
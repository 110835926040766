import React, { useEffect } from 'react'
// import About from '../../components/About/About'
// import AboutDirector from '../../components/About/AboutDirector'
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/Navigation bar/NavBar';
import HeadImage from '../../components/Header/HeadImage';
import AboutUs from '../../components/About/AboutUs';
// import MisVis from '../../components/About/MisVis';

const AboutSection = () => {
  useEffect(() => {
    document.title = 'Bansal Classes Sagar - About Us';
  }, []);
  return (
    <>
      <NavBar />
      <HeadImage title="About" />
      <AboutUs/>
      {/* <MisVis /> */}


      <Footer />
    </>
  )
}

export default AboutSection
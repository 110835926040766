import React from 'react'
import './jee.css'
import Img from '../../assets/PreFond.webp'
import CoursesTab from './CoursesTab'
import { Link } from 'react-router-dom'
const PreFoundationSection = () => {
    return (
        <>
            <div className="courses">

                <p className='course-section-heading'>Course Details</p>
                <div className="main-box">


                    <div className="courses-list">


                        <div className="bbox-1 bbox">
                            <div className="bbox-img">
                                <img src={Img} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>GENIUS</h2>
                                <p className='bbox-course-subheading'>5TH TO 6TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>


                            </div>
                        </div>

                        <div className="bbox-1 bbox">
                            <div className="bbox-img">
                                <img src={Img} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>BRILLIANT</h2>
                                <p className='bbox-course-subheading'>6TH TO 7TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>


                            </div>
                        </div>



                        <div className="bbox-2 bbox">
                            <div className="bbox-img">
                                <img src={Img} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>OCTAGON</h2>
                                <p className='bbox-course-subheading'>7TH TO 8TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>

                            </div>
                        </div>

                        <div className="bbox-3 bbox">
                            <div className="bbox-img">
                                <img src={Img} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>PEARL</h2>
                                <p className='bbox-course-subheading'>8TH TO 9TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>

                            </div>
                        </div>

                        <div className="bbox-4 bbox">
                            <div className="bbox-img">
                                <img src={Img} alt="" />
                            </div>
                            <div className="bbox-p">
                                <h2 className='bbox-course-heading'>ZENITH</h2>
                                <p className='bbox-course-subheading'>9TH TO 10TH MOVING</p>
                                <p className='bbox-course-p'> This 1 Year Programme lay a strong foundation & prepare the
                                    students for Competitive Examinations organized at state or National Level like NTSE,
                                    IJSO, NSTSE & Various Olympiads like NSO, IMO, IEO, UIEO, UCO,
                                    etc & also ensure excellence in school exams.
                                    <p className='space'>For Admissions - <Link to='/boost'>Click Here</Link></p>
                                </p>

                            </div>
                        </div>
                    </div>


                    <CoursesTab />
                </div>
            </div>

        </>
    )
}

export default PreFoundationSection
import React from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import HeadImage from '../../components/Header/HeadImage'
import Footer from '../../components/Footer/Footer'
import TestResult from '../../components/TestResults/TestResult'

const Updates = () => {
  return (
    <>
        <NavBar />
        <HeadImage title="Test Results" />
        <TestResult/>
        <Footer />
    </>
  )
}

export default Updates
import React, { useEffect } from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import Footer from '../../components/Footer/Footer'
import HeadImage from '../../components/Header/HeadImage'
import PreFoundationSection from '../../components/Courses/PreFoundationSection'



const PreFoundation = () => {
    useEffect(() => {
        document.title = 'Bansal Classes Sagar - PreFoundation Courses';
    }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="PRE FOUNDATION"/>
            <PreFoundationSection/>

            <Footer />



        </>
    )
}

export default PreFoundation
import React from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import HeadImage from '../../components/Header/HeadImage'
import Tips from '../../components/TestResults/Tips'
import Footer from '../../components/Footer/Footer'

const ExpertTips = () => {
  return (
    <>
    <NavBar/>
    <HeadImage title="Top Strategies for Cracking IIT JEE"/>
    <Tips/>
    <Footer/>
    </>
  )
}

export default ExpertTips
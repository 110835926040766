import React, { useEffect, useRef, useState } from 'react'
import './stureg.css'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Footer from '../../components/Footer/Footer';
// import dayjs from 'dayjs';
import NavBar from '../../components/Navigation bar/NavBar';
import HeadImage from '../../components/Header/HeadImage';
// import Footer from '../../components/Footer/Footer';


const StudentReg = () => {

    useEffect(() => {
        document.title = 'Bansal Classes Sagar - Student Registration';
    }, []);

    

    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwUpKcs7hPSRbDUMoVvAhI1jkaBjIPASF2v22ACJkX2xab7RqP9SlVhZsQdY5Q42WBf/exec";
    const [loading, setLoading] = useState(false)

    
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            setLoading(false)
            formRef.current.reset()
            setGender("")
            setClasses("")
            setCourse("")
            setPack("")
            setSelectedCourse([])
        })
            .catch(err => console.log(err))
    }

    const [gender, setGender] = React.useState('');
    // const [date, setDate] = React.useState('');
    const [classes, setClasses] = React.useState('');
    const [course, setCourse] = React.useState('');
    const [pack, setPack] = React.useState('');
    const [selectedCourse, setSelectedCourse] = React.useState([]);
    // const dayAfter = dayjs().add(2, 'day');
    const handleChangeGender = (event) => {
        setGender(event.target.value);
    };

    const handleCourseChange = (event) => {
        setCourse(event.target.value);
        if (event.target.value === "Jee Advanced") {
            setSelectedCourse(JEEList);
        }

        else if (event.target.value === "Neet UG") {
            setSelectedCourse(NEETList);
        }

        else if (event.target.value === "Pre-foundation") {
            setSelectedCourse(FoundationList);
        }
    }

    const JEEList = [
        {
            key: 0,
            name: "BULL'S EYE FOR JEE (10th - 11th Moving)",
            value: "BULL'S EYE FOR JEE",
        },

        {
            key: 1,
            name: "NUCLEUS FOR JEE (11th to 12th Moving)",
            value: "NUCLEUS FOR JEE",
        },
        {
            key: 2,
            name: "STERLING FOR JEE (12th passed out)",
            value: "STERLING FOR JEE",
        },

    ];

    const NEETList = [
        {
            key: 0,
            name: "BULL'S EYE FOR NEET (10th - 11th Moving)",
            value: "BULL'S EYE FOR NEET",
        },
        {
            key: 1,
            name: "NUCLEUS FOR NEET (11th to 12th Moving)",
            value: "NUCLEUS FOR NEET",
        },
        {
            key: 2,
            name: "STERLING FOR NEET (12th passed out)",
            value: "STERLING FOR NEET",
        },
    ];

    const FoundationList = [
        {
            key: 0,
            name: "GENIUS (6th)",
            value: "GENIUS",
        },
        {
            key: 1,
            name: "BRILLIANT (7th)",
            value: "BRILLIANT",
        },
        {
            key: 2,
            name: "OCTAGON (8th)",
            value: "OCTAGON",
        },
        {
            key: 3,
            name: "PEARL (9th)",
            value: "PEARL",
        },
        {
            key: 4,
            name: "ZENITH (10th)",
            value: "ZENITH",
        },
    ];

    return (
        <>
            <NavBar />

            <HeadImage title="Student Registrations" />

            <div className="registration-form">
                <div className="heading">
                    <p className='form-reg-heading'>Registration Form</p>
                    <p className='form-reg-subheading'>please fill all the required informations</p>
                </div>
                <div className="boxform">
                    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                        {/* <div className="form-box"> */}



                        <div className="spec-form">
                            <TextField
                                required
                                id="outlined-required"
                                label="Your First Name"
                                name="First Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Your Last Name"
                                name='Last Name'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Father's Name"
                                name="Father's Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <FormControl required fullWidth>
                                <InputLabel id="type-label">Gender</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={gender}
                                    onChange={handleChangeGender}
                                    label="Age"

                                >
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DateField label="Date of Birth" name='Date of Birth'
                                        className='date-picker'
                                        format='DD/MM/YYYY'
                                        defaultValue=''
                                        disableFuture />
                                </DemoContainer>
                            </LocalizationProvider>



                            <FormControl required fullWidth>
                                <InputLabel id="class-label">Class</InputLabel>
                                <Select
                                    labelId="class-label"
                                    id="class"
                                    name="class"
                                    value={classes}
                                    onChange={(event) => {
                                        setClasses(event.target.value)
                                    }}
                                    label="Class"
                                    defaultValue={""}
                                    placeholder='Select Class'
                                >
                                    <MenuItem value={"7"}>7<sup>th</sup></MenuItem>
                                    <MenuItem value={"8"}>8<sup>th</sup></MenuItem>
                                    <MenuItem value={"9"}>9<sup>th</sup></MenuItem>
                                    <MenuItem value={"10"}>10<sup>th</sup></MenuItem>
                                    <MenuItem value={"11"}>11<sup>th</sup></MenuItem>
                                    <MenuItem value={"12"}>12<sup>th</sup></MenuItem>
                                    <MenuItem value={"13"}>12<sup>th</sup> Passed</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Course-label">Course</InputLabel>
                                <Select
                                    labelId="Course-label"
                                    id="Course"
                                    name="Course"
                                    value={course}
                                    onChange={handleCourseChange}
                                    label="Course"
                                    defaultValue=''
                                >
                                    <MenuItem value={"Jee Advanced"} >JEE Advanced</MenuItem>
                                    <MenuItem value={"Neet UG"} >NEET UG</MenuItem>
                                    <MenuItem value={"Pre-foundation"}>Pre Foundation</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Course-label">Select Course</InputLabel>
                                <Select
                                    labelId="Select-Course-label"
                                    id="pack"
                                    name="pack"
                                    value={pack}
                                    onChange={(event) => {
                                        setPack(event.target.value)
                                    }}
                                    label="Pack"
                                    defaultValue=''
                                >
                                    {
                                        selectedCourse.map((courseList, key) => (
                                            <MenuItem key={courseList.key} value={courseList.value}>
                                                {courseList.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                id="outlined-required"
                                label="Address"
                                name='address'
                                rows={4}

                                className='addressInput'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="City"
                                name='city'
                                rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="State"
                                name='state'
                            // rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Zip"
                                type='number'
                                maxLength={6}
                                name='zip'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Phone Number"
                                name='phone-number'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />



                            <TextField
                                id="outlined-required"
                                label="Alternative Phone Number"
                                name='alternative-phone'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />


                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                type='email'
                                name='email'
                                className='email'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <input type="submit" className="btn-regform myBtn" id="sub-btn"
                                value={loading ? "Loading..." : "Submit"} />
                        </div>

                    </form >
                    {/* </div> */}
                </div >
            </div >
            <Footer />



        </>
    )
}

export default StudentReg
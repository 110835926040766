import React from 'react'
import Img1 from '../../assets/logonew.png'
import Img2 from '../../assets/boost png.svg'
import Img3 from '../../assets/img2.svg'
import './boost.css'
import Img from '../../assets/boy.png'
import { useNavigate } from 'react-router-dom'
import DetailsTab from './DetailsTab'
const BoostsComponent = () => {

    const navigate = useNavigate();

    return (
        <>
            <section>

                <div className="boost-info-boxes">
                    <div className="boost-info-box">
                        <h3>Who We Are?</h3>
                        <img src={Img1} alt="icon" className='' />
                        <p>
                            Bansal Classes Kota, The Most Popular Brand in JEE Coaching. The only classroom coaching to deliver
                            Five Times AIR-1 (All India Rank-1) in the JEE since its inception.
                        </p>
                    </div>
                    <div className="boost-info-box">
                        <h3>What Is BOOST?</h3>
                        <img src={Img2} alt="icon" />
                        <p>
                            BOOST is a National level Scholarship cum Real Time
                            Aptitude testing platform for the upcoming Global leaders.
                            BOOST gives an unique opportunity for students to test their
                            knowledge and elevate their future academic strength.
                        </p>
                    </div>
                    <div className="boost-info-box">
                        <h3>Who Can Participate?</h3>
                        <img src={Img3} alt="icon" className='' />
                        <p>
                            Class V, VI, VII, VIII, IX, X, XI
                            (Engineering and Medical) and XII
                            (Engineering and Medical).
                        </p>
                    </div>
                </div>

                <div className="boost-intro">
                    <div className="boost-text-section">
                        <h2>Boost</h2>
                        <h3>Bansal Open Opportunity & Scholarship Test</h3>
                        <p>Enroll yourself for the most prestigious scholarship Test of India for getting admission in Bansal Classes, Kota, and study centers across India. With dedication and positive attitude, Bansal Classes produced wonderful results each year which challenged
                            them to produce even better results.</p>
                        <h3>Call Us: <span className='black-text'>6232102603</span></h3>
                        <button onClick={()=> navigate('/boost-registration')}>Enroll Now</button>
                    </div>

                    <div className="boost-logo-section">
                        <img src={Img} alt="" />
                    </div>

                </div>

                <DetailsTab/>

            </section>
        </>
    )
}

export default BoostsComponent
import React from 'react'
import NavBar from '../../components/Navigation bar/NavBar'
import HeadImage from '../../components/Header/HeadImage'
import SuccessStory from '../../components/TestResults/SuccessStory'
import Footer from '../../components/Footer/Footer'

const SuccessStorys = () => {
  return (
    <>
        <NavBar/>
        <HeadImage title="Success Stories"/>
        <SuccessStory/>
        <Footer/>
    </>
  )
}

export default SuccessStorys
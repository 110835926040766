import React from 'react'
import Img from '../../assets/dir-img.jpeg'
import './aboutus.css'
const AboutUs = () => {
    return (
        <>
            <div className="about-us-main">

                <div className="about-heading">
                    <h2>
                        Pioneer of NEET UG and IIT-JEE Coaching
                    </h2>
                    <p>
                        “Leadership is the capacity to translate vision into reality.”
                    </p>
                </div>


                <div className="about-text-box">

                    <img src={Img} alt="" />


                    <div className="about-us-text-main">
                        <h2>CMD - V.K Bansal</h2>
                        <h4>Dear Students,</h4>
                        <p>
                            I welcome all of you to Bansal Classes and earnestly wish success to each one of you. And though I would sincerely hope that all of you make it to the respective institutes that you’ve set your minds upon, what I mean by ‘success’ is something altogether different.
                            <br />
                            <br />
                            For me, success simply means being able to perform to your potential. For example, an average student who by dint of his hard work makes it to the waiting list of NEET UG or IIT-JEE, is for me successful than a superlatively brilliant student who because of his complacence and casualness got a rank of 500. Over the years, it has been my experience that the memories which troubles us most are not those of our misfortune, but those that have the words ‘..if only I had done that..’ embedded in them.
                            <br />
                            <br />
                            So my only advice to all of you is this; be it engineering entrance or any other endeavor in life, make sure that these words never find a port of entry into the picture. Spare no effort, work hard and live up to your potential. Whatever follows will always be for the best. That, according to me, is the simple calculus of Karma
                        </p>
                    </div>
                </div>



                <div className="why-sagar-box">
                    <div className="why-sagar">
                        <h2>Why In Sagar?</h2>
                        <p>It is the vision of Sameer Bansal sir to make Sagar as model centre for MP students. Further Sagar city has been
                            chosen as 1st Centre of Bansal Classes KOTA in MP considering the following:</p>
                        <ol>
                            <li>Sagar smart city is equipped with all amenities and facilities.</li>
                            <li>Sagar city is well connected with rail, road transport.</li>
                            <li>To make quality education available for all students in Bundelkhand region. </li>
                            <li>To make affordable quality educational guidance for students at Sagar.</li>
                            <li>To address anxiety of parents who doesn’t want to send their Childrens outside Sagar without compromising on quality. </li>
                            <li>The cost of living for sending students to Sagar is very less compared to Bhopal, Indore, Kota and hence affordable to parents.</li>
                            <li>To provide proper guidance for highly potential students in Bundelkhand region of India</li>
                        </ol>
                    </div>
                    <div className="why-sagar">
                        <h2>Bansal Classes: Sagar Study Centre
                            Facilities
                        </h2>

                        <ol>
                            <li>First Time All Faculties are from KOTA in SAGAR</li>
                            <li>All Teachers / Faculties are IITians, Gold Medallists, Well Experienced(15+ yrs.), Highly Qualified and deployed form Bansal Classes HQ, Kota Centre.</li>
                            <li>Location of Bansal Classes Sagar Study Centre is at Centre of the City i.e. At Civil Lines, SAGAR. </li>
                            <li>There is No Compromise on Infrastructure and Students Comfort.</li>
                            <li>Dedicated team of Counsellors : For Regular Guidance of Students.</li>
                            <li>Career Counselling Sessions – By IAS, IPS, IRS Officers, Business Tycoons.</li>
                            <li>Provision of necessary accommodation for Students coming from Outside Sagar.</li>
                        </ol>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AboutUs
import React, { useEffect } from 'react'
import NavBar from '../components/Navigation bar/NavBar'
import Footer from '../components/Footer/Footer'
import HeadImage from '../components/Header/HeadImage'
import BoostsComponent from '../components/Boosts/BoostsComponent'
const BoostPage = () => {

    useEffect(() => {
        document.title = 'Boosts Exam';
      }, []);
    return (
        <>
            <NavBar />
            <HeadImage title="Boosts Exam" />

            <BoostsComponent/>

            <Footer />
        </>
    )
}

export default BoostPage
import React from 'react'
import SMImg from '../../assets/smbansal.webp'
import MBImg from '../../assets/mahima_bansal.webp'
import NbImg from '../../assets/neelam_bansal.webp'
import './dirmsg.css'
const DirectorsMessage = () => {
    return (
        <>

            <div className="directors-msg-section">
                <div className="heading-dir-msg-section">
                    <h2>Messages From Directors</h2>

                </div>

                <div className="sm-bansal-msg bansal-msg">
                    <img src={SMImg} alt="" />


                    <div className="about-us-text-main">
                        <h2>MD - S.M Bansal</h2>
                        <h4>Meeting the Expectations</h4>
                        <p>
                            To reach heights one must start climbing and if the journey is difficult then perseverance is the key to success. As a teacher I have realized over past years that success in NEET UG and IIT-JEE requires hard work and proper guidance. One must be able to draw a line of demarcation among what to study and what not to.
                            <br />
                            <br />
                            Bansal Classes with its unique teaching methodologies have always proved that we meet the expectations of thousands of students and parents to make their dreams come true. With changing patterns we have adapted ourselves to deliver the best and ensure better results. I believe that changes motivate to move ahead and thus we welcome the new pattern of NEET UG and IIT-JEE with great vigor to conquer it.

                        </p>
                    </div>
                </div>

                <div className="nb-bansal-msg bansal-msg">
                    <img src={NbImg} alt="" />


                    <div className="about-us-text-main">
                        <h2>Director - Neelam Bansal</h2>
                        {/* <h4>Meeting the Expectations</h4> */}
                        <p>
                            Dear Students, In today’s stiff competitive environment for competitive exams, students face unprecedented challenges and the opportunity is - a preparatory course at Bansal Classes. This course gives you the tools to meet the challenges of competitive exams and capitalize on new opportunities throughout your career.
                            <br />
                            <br />
                            I have always believed that excellence in education can make a difference in person’s world. I would like to express my gratitude for the faith and interest you have shown in Bansal Classes. We believe in cultivating unique strengths of each individual student. Our sincere efforts have always channelized to create technocrats / Doctors of tomorrow’s world.
                            <br />
                            <br />
                            We combine up-to-the minutest knowledge with relevant, real competitive exams experiences, and we continually search for new and better ways to deliver course material. We are justifiably proud of our ability to provide students with a challenging, nurturing and spirited environment. Our dreams have been woven with rewarding careers of our students. We strive to accomplish this by supporting them in their efforts through contemporary academic programs, practices, qualified faculties and an efficient administration.
                            <br />
                            <br />
                            What makes Bansal Classes so singular is that we believe that truth can be arrived at only through hard work. I can assure you that our association will be one of the most cherished decisions of your life. Bansal Classes is a place where you will be challenged to achieve your potential, find support as you shape up your future. I ensure that no stone will remain unturned in the endeavour to ensure you a great career ahead.
                            <br />
                            <br />
                            Wishing you a very bright future !

                        </p>
                    </div>
                </div>

                <div className="nb-bansal-msg bansal-msg">
                    <img src={MBImg} alt="" />


                    <div className="about-us-text-main">
                        <h2>Director - Mahima Bansal</h2>
                        <h4>"Consistent Approach"</h4>
                        <p>
                            Dear Student, I believe that your hard work should always be a vector quantity rather than a scalar one, which has a magnitude as well a direction.
                            <br />
                            <br />
                            The efforts put by you will be the magnitude and our guidance will be the direction to reach your destination of IITs and other competitive examinations. It’s important to note at this juncture that the changing pattern of IITJEE will have several new challenges to answer and a consistent approach will be required.
                            <br />
                            <br />
                            I would reinstate the fact that our R & D department under the guidance of our senior faculties has designed the curriculum to meet all the requirements of the new pattern. The direction is assured, now it’s your turn to add magnitude to it.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DirectorsMessage